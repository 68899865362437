import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import './global.less'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import AMapLoader from '@amap/amap-jsapi-loader'
import mitt from 'mitt'
dayjs.locale('zh-cn')
const app = createApp(App)
app.config.globalProperties.$bus = mitt()
app.use(router).use(Antd).use(AMapLoader).mount('#app')

