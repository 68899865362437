<script setup>
import { h, onMounted, reactive, ref } from 'vue'
import MapContainer from '@/components/map'
import {
  AimOutlined,
  EditOutlined,
  EnvironmentOutlined,
  PlusOutlined,
  HomeOutlined, DeleteOutlined,
  UserOutlined, FieldBinaryOutlined
} from '@ant-design/icons-vue'
import {
  commitNewAddress, deleteAddress,
  fetchAddressDetail,
  fetchAddressList,
  fetchOrderDetail,
  fetchOrderList, updateAddress,
  updatePickUpMethod
} from '@/service'
import { message } from 'ant-design-vue'
import { regionData, codeToText, pcaTextArr } from 'element-china-area-data'

const contentStyle = {
  textAlign: 'center',
  height: '100%',
  lineHeight: '120px',
  color: 'black',
  backgroundColor: 'white'
}
const headerStyle = {
  textAlign: 'center',
  color: 'black',
  height: 64,
  paddingInline: 50,
  lineHeight: '64px',
  backgroundColor: 'white'
}
const siderStyle = {
  width: '400',
  textAlign: 'center',
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: 'white'
}
const data = ref([])
const pageSize = 10
const pagination = reactive({
  onChange: page => {
    fetchAddressList(page, pageSize).then(res => {
      while (data.value.length > 0)
        data.value.pop()
      loadOrderData(page, pageSize)
    })
  },
  pageSize: pageSize,
  total: 0
})
const address_pagination = {
  onChange: page => {
    console.log(page)
  },
  pageSize: pageSize
}
const getLogo = (index) => {
  const map = [
    new URL('../../assets/zhongtong.svg', import.meta.url).href,
    new URL('../../assets/jd.svg', import.meta.url).href,
    new URL('../../assets/yuantong.svg', import.meta.url).href,
    new URL('../../assets/shentong.svg', import.meta.url).href,
    new URL('../../assets/youzheng.svg', import.meta.url).href,
    new URL('../../assets/yunda.svg', import.meta.url).href,
    new URL('../../assets/shunfeng.svg', import.meta.url).href
  ]
  return map[index]
}
const getCompanyName = (index) => {
  let list = ['中通快递', '京东快递', '圆通快递', '申通快递', '邮政快递', '韵达快递', '顺丰快递']
  return list[index]
}
const statusMap = [
  '未发货', '待发车', '已发车', '到达中转', '已到达', '已签收'
]
const loadOrderData = (current, size) => {
  fetchOrderList(current, size).then(res => {
    let orderList = res.data
    pagination.total = res.total
    orderList.forEach(item => {
      fetchOrderDetail(item).then(detail => {
        let company_id = detail['data']['company_id']
        let order_id = detail['data']['order_id']
        let status = detail['data']['status']
        let pickup_methods = detail['data']['pickUpMethods']
        let createTime = detail['data']['createTime']
        let order_info = {
          'key': order_id,
          'pickup_method': pickup_methods,
          'orderid': '#' + order_id,
          'company': company_id,
          'position': statusMap[status],
          'update_time': createTime,
          'status': status,
          'address_id': detail['data']['delivery_address']
        }
        data.value.push(order_info)
      })
    })
  })
}


let currentOrder = reactive({
  orderId: '',
  address: '',
  status: '',
  city: '',
  ifDoorToDoorDelivery: false,
  phone: '',
  username: ''
})
const default_address = reactive({
  name: '',
  phone: '',
  detailed: '',
  region: '',
  city: '',
  district: '',
  key: '',
  is_default: false
})
const loadAddressData = (current, size) => {
  fetchAddressList(current, size).then(res => {

    let id_list = res.data
    id_list.forEach(id => {
      fetchAddressDetail(id).then(res => {
        let name = res['data']['name']
        let phone = res['data']['phone']
        let province = res['data']['province']
        let city = res['data']['city']
        let district = res['data']['district']
        let detail = res['data']['detail']
        let is_default = res['data']['is_default']
        if (is_default == null || is_default === 0)
          is_default = false
        else if (is_default === 1) {
          is_default = true
          default_address.name = name
          default_address.phone = phone
          default_address.detailed = detail
          default_address.region = province + city + district
          default_address.city = city
          default_address.district = district
          default_address.key = id
          default_address.is_default = true
        }

        my_address.value.push({
          'name': name,
          'phone': phone,
          'detailed': detail,
          'region': province + city + district,
          'province': province,
          'city': city,
          'district': district,
          'key': id,
          'is_default': is_default
        })
      })
    })
    console.log(my_address.value)
  })
}
onMounted(() => {
  loadOrderData(1, pageSize)
  loadAddressData(1, pageSize)
})


const confirmChangePickUpMethod = (res) => {
  data.value.forEach(item => {
    if (item['key'] === res['orderId'])
      item['pickup_method'] = res['pickUpMethod']
  })
}
const change_pickup_method = (orderId, addressId, method) => {

  fetchAddressDetail(addressId).then(res => {
    let phone = res['data']['phone']
    let name = res['data']['name']
    let province = res['data']['province']
    let city = res['data']['city']
    let district = res['data']['district']
    let detail = res['data']['detail']
    currentOrder.city = city
    currentOrder.address = province + city + district + detail
    currentOrder.phone = phone
    currentOrder.username = name
    currentOrder.ifDoorToDoorDelivery = method === 2
    currentOrder.orderId = orderId
    change_menu(1)
    message.info('请选择指定的驿站，快递将会送至你选定的驿站', 5)
  }).catch(error => {
    console.log(error)
    message.warn('地图加载失败，请稍后重试！', 5)
  })

}
const search_position = ref()
const onSearch = () => {

}
const change_menu = (idx) => {
  show_menu.value = idx
}
const my_address = ref([])
const show_menu = ref(0)
const curr_position = ref('获取定位中')
const showMethod = (pickup_methods, status) => {
  if (status === 5)
    return '订单完成'
  if (pickup_methods === 0)
    return '取件方式'
  if (pickup_methods === 1)
    return '驿站自取'
  if (pickup_methods === 2)
    return '上门投递'
}
const open = ref(false)
const hideModal = () => {
  open.value = false
}
const showModal = () => {
  open.value = true
}
const handleCommitAddress = () => {
  formRef.value.validate().then(() => {
    addAddressReq.province = codeToText[addAddressReq.address[0]]
    addAddressReq.city = codeToText[addAddressReq.address[1]]
    addAddressReq.district = codeToText[addAddressReq.address[2]]
    let form_data = new FormData()
    form_data.append('receiver', addAddressReq.name)
    form_data.append('phone', addAddressReq.phone)
    form_data.append('province', addAddressReq.province)
    form_data.append('city', addAddressReq.city)
    form_data.append('district', addAddressReq.district)
    form_data.append('is_default', addAddressReq.is_default ? 1 : 0)
    form_data.append('detail', addAddressReq.detail)
    commitNewAddress(form_data).then((res) => {
      my_address.value.push({
        'name': res.data.name,
        'phone': res.data.phone,
        'detailed': res.data.detail,
        'region': res.data.province + res.data.city + res.data.detail,
        'province': res.data.province,
        'city': res.data.city,
        'district': res.data.district,
        'key': res.data.id,
        'is_default': res.data.is_default === 1
      })
      message.info('添加地址成功！')
    }).catch(err => {
      console.log(err.errorMsg)
      message.warn('地址重复！')
    })
    hideModal()
  }).catch(err => {
    console.log(err)
  })

}
const addAddressReq = reactive({
  name: '',
  phone: '',
  detail: '',
  address: [],
  province: '',
  city: '',
  district: '',
  is_default: 0
})
const addAddressRules = {//校验规则
  name: [
    {
      required: true,
      message: '请输入姓名',
      trigger: 'change'
    },
    {
      min: 1,
      max: 32,
      message: '长度应为3到32之间',
      trigger: 'blur'
    }
  ],
  phone: [
    {
      required: true,
      message: '请输入手机号'
    }, {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: '手机号不符合规则'
    }
  ],
  address: [
    { required: true }
  ],
  detail: [
    {
      required: true
    }
  ],
  is_default: [
    {
      required: false
    }
  ]

}
const formRef = ref()
const editModalOpen = ref(false)
const hideEditModal = () => {
  editModalOpen.value = false
}
const showEditModal = () => {
  editModalOpen.value = true
}
const editButtonAction = (key, name, phone, province, city, district, detail, is_default) => {
  console.log(is_default)
  editAddressReq.name = name
  editAddressReq.phone = phone
  editAddressReq.province = province
  editAddressReq.city = city
  editAddressReq.district = district
  while (editAddressReq.address.length > 0)
    editAddressReq.address.pop()
  editAddressReq.address.push(...[province, city, district])
  editAddressReq.detail = detail
  editAddressReq.is_default = is_default
  editAddressReq.key = key
  showEditModal()
}
const handleUpdateAddress = () => {
  editAddressRef.value.validate().then(() => {
    let form_data = new FormData()
    let province = editAddressReq.address[0]
    let city = editAddressReq.address[1]
    let district = editAddressReq.address[2]
    form_data.append('addressId', editAddressReq.key)
    form_data.append('receiver', editAddressReq.name)
    form_data.append('phone', editAddressReq.phone)
    form_data.append('province', province)
    form_data.append('city', city)
    form_data.append('district', district)
    form_data.append('detail', editAddressReq.detail)
    form_data.append('is_default', editAddressReq.is_default ? 1 : 0)
    updateAddress(form_data).then(res => {
      message.info('地址修改成功！')
      // 之后修改对应的数据
      my_address.value.forEach(item => {
        if (item.key === editAddressReq.key) {
          item.name = editAddressReq.name
          item.phone = editAddressReq.phone
          item.detailed = editAddressReq.detail
          item.region = province + city + district
          item.province = province
          item.city = city
          item.district = district
          item.is_default = editAddressReq.is_default
        }
      })
    }).catch(err => {
      message.warn(err.errorMsg)
    })
    hideEditModal()
  }).catch(err => {
    // console.log(err)
    err.errorFields.forEach(item => {
      message.error(item.errors[0])
    })
  })
}
const editAddressRef = ref()
const editAddressReq = reactive({
  key: '',
  name: '',
  phone: '',
  detail: '',
  address: ref([]),
  province: '',
  city: '',
  district: '',
  is_default: false
})
const confirmDeleteAddress = (id) => {
  // console.log(id)
  deleteAddress(id).then(res => {
    message.info('删除成功！')
  })
}
const cancelDeleteAddress = () => {

}

</script>
<template>
  <a-layout class="container-wrapper">
    <a-layout-sider width="35%" class="side-style">
      <a-list :bordered='true' item-layout='horizontal' size='small' :pagination='pagination' :data-source='data'>
        <template #renderItem='{ item }'>
          <a-list-item :key='item.orderid'>
            <template #extra>
              <div>
                <a-popover title='取件方式'>
                  <template #content v-if="item.pickup_method===0 && item.status!==5">
                    <div><a key='station' @click='change_pickup_method(item.key,item.address_id,1)'>驿站自取</a>
                    </div>
                    <div><a key='door' @click='change_pickup_method(item.key,item.address_id,2)'>上门投递</a></div>
                  </template>
                  <a-button type="link" :disabled="item.pickup_method!==0 || item.status===5">
                    {{ showMethod(item.pickup_method, item.status) }}
                  </a-button>
                </a-popover>
              </div>
            </template>
            <a-list-item-meta>
              <template #title>
                {{ item.orderid }}
              </template>
              <template #avatar>
                <a-avatar :src='getLogo(item.company)' />
              </template>
              <template #description>
                <div>
                  <span>{{ getCompanyName(item.company) }}</span>
                </div>
                <div>
                  {{ item.update_time }}
                </div>
              </template>
            </a-list-item-meta>
            <div>{{ item.position }}</div>
          </a-list-item>
        </template>
      </a-list>
    </a-layout-sider>
    <a-layout-content class="content-style">
      <a-layout-header class="content-header-style">
        <a-row>
          <a-col class="hover_style" :span="12">
            <a-button type="text" @click="change_menu(0)" :disabled="show_menu===0">
              <UserOutlined />
              我的驿站
            </a-button>
          </a-col>
          <a-col class="hover_style" :span="12">
            <a-button type="text" @click="change_menu(1)" :disabled="show_menu===1">
              <EnvironmentOutlined />
              选择其他驿站
            </a-button>
          </a-col>
        </a-row>
      </a-layout-header>
      <a-divider style="height: 1px;margin: 0;" />
      <a-layout-content class="content-container-style">
        <div v-if="show_menu" style='width: 100%;height: 70vh;'>
          <a-flex vertical>
            <div style="line-height: 30px;height:30px;margin: 10px">
              <a-row>
                <a-col :span="24">
                  <a-input-search
                    v-model:value="search_position"
                    placeholder="输入地址、查找附近驿站"
                    enter-button
                    @search="onSearch"
                  />
                </a-col>
              </a-row>
            </div>
            <div style="width: 100%;height: 72.4vh;">
              <MapContainer :city="currentOrder.city" :address="currentOrder.address"
                            :if-door-to-door-delivery="currentOrder.ifDoorToDoorDelivery"
                            :order-id="currentOrder.orderId"
                            :default_address="default_address"
                            @confirm="confirmChangePickUpMethod"
              />
            </div>

          </a-flex>
        </div>
        <div v-if="!show_menu" style="width: 100%;height: 70vh">
          <a-layout-content>
            <a-list :bordered='true' item-layout="horizontal" size='small' :pagination='address_pagination'
                    :data-source='my_address'>
              <template #header>
                <a-row style="color:#787878">
                  <a-col :span="4">
                    <a-button @click.stop="showModal" :icon="h(EditOutlined)">管理收货地址</a-button>
                  </a-col>
                  <a-col :span="4">
                    <a-button @click.stop="showModal" :icon="h(PlusOutlined)">添加收货地址</a-button>
                  </a-col>
                </a-row>
              </template>
              <template #renderItem='{ item }'>
                <a-list-item :key='item.key'>
                  <template #actions>
                    <div>
                      <a-popover>
                        <template #content>
                          此地址为默认地址
                        </template>
                        <a-button
                          :style="{ visibility: item.is_default ? 'visible' : 'hidden' }"
                          type="text"
                          :icon="h(FieldBinaryOutlined)">
                        </a-button>
                      </a-popover>
                      <a-button
                        @click.stop="editButtonAction(item.key,item.name,item.phone,item.province,item.city,item.district,item.detailed,item.is_default)"
                        :icon="h(EditOutlined)"
                        type="text"
                      ></a-button>
                      <a-popconfirm
                        placement="left"
                        title="确认删除？"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="confirmDeleteAddress(item.key)"
                        @cancel="cancelDeleteAddress"
                      >
                        <a-button
                          :icon="h(DeleteOutlined)"
                          type="text"
                          danger></a-button>
                      </a-popconfirm>
                    </div>
                  </template>
                  <a-list-item-meta>
                    <template #title>
                      {{ item.detailed }}
                    </template>
                    <template #description>
                      <span>{{ item.name }} </span>
                      <span>{{ item.phone }}</span>
                    </template>
                  </a-list-item-meta>
                  <div>{{ item.province + item.city + item.district }}</div>
                </a-list-item>
              </template>
            </a-list>
          </a-layout-content>
        </div>
      </a-layout-content>
    </a-layout-content>


  </a-layout>
  <a-modal :open="open"
           :mask="false"
           :keyboard="true"
           :title="'添加收货地址'"
           :closable="true"
           @cancel="hideModal"
           :mask-closable="true"
           :destroy-on-close="true"
           @ok="handleCommitAddress"
  >
    <a-form
      :model="addAddressReq"
      :rules="addAddressRules"
      layout="horizontal"
      ref="formRef"
    >
      <a-form-item ref="name" label="收货人姓名" name="name">
        <a-input v-model:value="addAddressReq.name" placeholder="收货人姓名" />
      </a-form-item>
      <a-form-item ref="phone" label="收货人手机号" name="phone">
        <a-input v-model:value="addAddressReq.phone" placeholder="收货人手机号" />
      </a-form-item>
      <a-form-item ref="address" label="地址" name="address">
        <a-cascader
          v-model:value="addAddressReq.address"
          :options="regionData"
          placeholder="选择收货地址"
          size="default"
        >
          <template #suffixIcon>
            <HomeOutlined />
          </template>
        </a-cascader>
      </a-form-item>
      <a-form-item ref="is_default" label="默认地址" name="is_default">
        <a-switch v-model:checked="addAddressReq.is_default" />
      </a-form-item>
      <a-form-item ref="detail" label="详细地址" name="detail">
        <a-input v-model:value="addAddressReq.detail" placeholder="详细地址" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal :open="editModalOpen"
           :mask="false"
           :keyboard="true"
           :title="'添加收货地址'"
           :closable="true"
           @cancel="hideEditModal"
           :mask-closable="true"
           :destroy-on-close="true"
           @ok="handleUpdateAddress"
  >
    <a-form
      :model="editAddressReq"
      :rules="addAddressRules"
      layout="horizontal"
      ref="editAddressRef"
    >
      <a-form-item ref="name" label="收货人姓名" name="name">
        <a-input v-model:value="editAddressReq.name" placeholder="收货人姓名" />
      </a-form-item>
      <a-form-item ref="phone" label="收货人手机号" name="phone">
        <a-input v-model:value="editAddressReq.phone" placeholder="收货人手机号" />
      </a-form-item>
      <a-form-item ref="address" label="地址" name="address">
        <a-cascader
          v-model:value="editAddressReq.address"
          :options="pcaTextArr"
          placeholder="选择收货地址"
          size="default"
        >
          <template #suffixIcon>
            <HomeOutlined />
          </template>
        </a-cascader>
      </a-form-item>
      <a-form-item ref="is_default" label="默认地址" name="is_default">
        <a-switch v-model:checked="editAddressReq.is_default" />
      </a-form-item>
      <a-form-item ref="detail" label="详细地址" name="detail">
        <a-input v-model:value="editAddressReq.detail" placeholder="详细地址" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style scoped>
.hover_style:hover {
  cursor: pointer;
}

.content-header-style {
  width: 100%;
  height: 8%;
  border: #252b30;
  background-color: white;
  color: #2f2f2f;
}

.content-container-style {

}

.container-wrapper {
  height: 97%;
  overflow: hidden;
  width: 99%;
}

.side-style {
  text-align: center;
  height: 100%;
  color: black;
  background-color: white;
  width: 100%;
  overflow: auto;
}

.content-style {
  text-align: center;
  line-height: 120px;
  color: #FFF;
  background-color: white;
  width: 65%;
  overflow: auto;
}
</style>