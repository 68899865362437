import { message } from 'ant-design-vue'
import Axios from 'axios'
import jsCookie from 'js-cookie'


const initAxios = Axios.create({
  timeout: 6000
})

//请求拦截器
initAxios.interceptors.request.use(
  (config) => {

    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Credentials'] = 'true'
    config.headers['Access-Control-Allow-Methods'] = '*'
    config.headers['Authorization'] = jsCookie.get('token') == null ? '' : jsCookie.get('token')
    return config
  },
  (error) => {
    return error
  }
)

//响应拦截器
initAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error, 'error')
    return Promise.reject(error)
  }
)

export default initAxios
