<script setup>
import { onMounted, reactive, ref } from 'vue'
import { h } from 'vue'
import eventBus from '@/utils/event_bus'
import {
  ClockCircleOutlined, CheckCircleFilled
} from '@ant-design/icons-vue'
import {
  fetchAddressDetail,
  fetchAddressList, fetchCourierInfo, fetchGoodsInfo,
  fetchOrderDetail,
  fetchOrderDetailByOrderId,
  fetchOrderList
} from '@/service'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import event_bus from '@/utils/event_bus'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const data = ref([])
const pageSize = ref(10)
const pagination = reactive({
  onChange: page => {
    console.log(page)
    fetchAddressList(page, pageSize.value).then(res => {
      while (data.value.length > 0)
        data.value.pop()
      loadOrderData(page, pageSize.value)
    })
  },
  pageSize: pageSize.value,
  total: 0
})
const getLogo = (index) => {
  const map = [
    new URL('../../assets/zhongtong.svg', import.meta.url).href,
    new URL('../../assets/jd.svg', import.meta.url).href,
    new URL('../../assets/yuantong.svg', import.meta.url).href,
    new URL('../../assets/shentong.svg', import.meta.url).href,
    new URL('../../assets/youzheng.svg', import.meta.url).href,
    new URL('../../assets/yunda.svg', import.meta.url).href,
    new URL('../../assets/shunfeng.svg', import.meta.url).href
  ]
  return map[index]
}
const getCompanyName = (index) => {
  let list = ['中通快递', '京东快递', '圆通快递', '申通快递', '邮政快递', '韵达快递', '顺丰快递']
  return list[index]
}

onMounted(() => {
  loadOrderData(1, pageSize.value)
})

const change_pickup_method = (key, orderid, method) => {
  data.value[key].pickup_method = method
}

const statusMap = [
  '未发货', '待发车', '已发车', '到达中转', '已到达', '已签收'
]
const loadOrderData = (current, size) => {
  fetchOrderList(current, size).then(res => {
    let orderList = res.data
    let current = res.current
    pagination.total = res.total
    orderList.forEach(item => {
      fetchOrderDetail(item).then(detail => {
        let company_id = detail['data']['company_id']
        let order_id = detail['data']['order_id']
        let status = detail['data']['status']
        let pickup_methods = detail['data']['pickUpMethods']
        let createTime = detail['data']['createTime']
        let order_info = {
          'key': order_id,
          'pickup_method': pickup_methods,
          'orderid': '#' + order_id,
          'company': company_id,
          'position': statusMap[status],
          'update_time': createTime
        }
        data.value.push(order_info)
      }).catch(err => {
        console.log(err)
      })
    })
  }).catch(err => {
    console.log(err)
  })
}
onMounted(() => {
  loadOrderData(1, pageSize.value)
})




const items = ref([
  {
    title: '未发货',
    status: 'wait',
    icon: h(ClockCircleOutlined)
  },
  {
    title: '待发车',
    status: 'wait',
    icon: h(ClockCircleOutlined)
  },
  {
    title: '已发车',
    status: 'wait',
    icon: h(ClockCircleOutlined)
  }, {
    title: '到达中转',
    status: 'wait',
    icon: h(ClockCircleOutlined)
  }, {
    title: '已到达',
    status: 'wait',
    icon: h(ClockCircleOutlined)
  }, {
    title: '已签收',
    status: 'wait',
    icon: h(ClockCircleOutlined)
  }
])

const item_detail = reactive({
  id: '',
  name: '',
  create_time: '',
  last_update_time: '',
  arriveTime: 0,
  postage: 0,
  status: '',
  is_running: '',
  base_info: {
    weight: '',
    payType: '',
    courier_name: '',
    type: '',
    pickup_methods: '',
    courier_phone: '',
    price: ''
  },
  shipping: {
    name: '',
    province: '',
    city: '',
    district: '',
    detail: '',
    phone: ''
  },
  delivery: {
    name: '',
    phone: '',
    province: '',
    city: '',
    district: '',
    detail: ''
  }
})
const is_order_running = (status) => {
  if (status === 5)
    return 'Finished'
  return 'Running'
}
const select_order = ref(0)
const select_item_detail = (item_id) => {
  select_order.value = parseInt(item_id)
  fetchOrderDetailByOrderId(item_id).then(res => {
    let company_id = res['data']['company_id']
    let createTime = res['data']['createTime']
    let delivery_address_id = res['data']['delivery_address']
    let goods_id = res['data']['goods_id']
    let pick_up_method = res['data']['pickUpMethods']
    let shipping_address_id = res['data']['shipping_address']
    let status = res['data']['status']
    let courier_id = res['data']['courier_id']
    item_detail.id = res['data']['order_id']
    item_detail.base_info.pickup_methods = pick_up_method
    item_detail.status = res['data']['status']
    item_detail.postage = res['data']['postage']
    item_detail.create_time = res['data']['createTime']
    item_detail.last_update_time = res['data']['lastUpdateTime']
    item_detail.arriveTime = res['data']['arriveTime']
    item_detail.payType = res['data']['payType']
    for (let i = 0; i < status; i++) {
      items.value[i].status = 'finish'
      items.value[i].icon = h(CheckCircleFilled)
    }
    items.value[status].status = 'process'
    items.value[status].icon = h(CheckCircleFilled)
    for (let i = status + 1; i < 6; i++) {
      items.value[i].status = 'wait'
      items.value[i].icon = h(ClockCircleOutlined)
    }
    fetchAddressDetail(delivery_address_id).then(res => {
      item_detail.delivery.phone = res['data']['phone']
      item_detail.delivery.name = res['data']['name']
      item_detail.delivery.province = res['data']['province']
      item_detail.delivery.city = res['data']['city']
      item_detail.delivery.district = res['data']['district']
      item_detail.delivery.detail = res['data']['detail']
    }).catch(error => {
      console.log(error)
      message.warn('收货地址获取失败！')
    })
    fetchAddressDetail(shipping_address_id).then(res => {
      item_detail.shipping.detail = res['data']['detail']
      item_detail.shipping.name = res['data']['name']
      item_detail.shipping.province = res['data']['province']
      item_detail.shipping.phone = res['data']['phone']
      item_detail.shipping.district = res['data']['district']
      item_detail.shipping.city = res['data']['city']
    }).catch(err => {
      console.log(err)
      message.warn('发货地址获取失败！')
    })
    fetchGoodsInfo(goods_id).then(res => {
      item_detail.base_info.type = res['data']['type']
      item_detail.base_info.weight = res['data']['weight']
      item_detail.base_info.payType = res['data']['payType']
      // item_detail.base_info.pickup_methods = res['data']['pickUpMethod']
    }).catch(error => {
      console.log(error)
    })
    fetchCourierInfo(courier_id).then(res => {
      item_detail.base_info.courier_name = res['data']['name']
      item_detail.base_info.courier_phone = res['data']['phone']
    }).catch(err => {
      console.log('快递员信息加载失败！')
      console.log(err)
    })


  }).catch(err => {
    console.log(err)
  })
}

const getGoodsType = (type) => {
  let type_list = ['家用电器', '数码3C', '电脑/办公', '家居/家装', '服装', '美妆清洁', '鞋服箱包', '运动户外', '房产/汽车用品',
    '母婴用品', '食品酒类生鲜']
  return type_list[type]
}

const isButtonAvailable = (status) => {
  return status >= 5
}
const router = useRouter()
const applyAfterSale = (order_id) => {
  router.push({
    path: '/customer-service'
  }).then(() => {
    eventBus.emit('applyAfterSale', {
      'orderId': order_id
    })
  })

}

const showPickUpMethods = (pickup_methods, status) => {
  console.log(pickup_methods)
  console.log(status)
  if (status === 5)
    return '订单完成'
  if (pickup_methods === 0)
    return '未选择'
  if (pickup_methods === 1)
    return '驿站自取'
  if (pickup_methods === 2)
    return '上门投递'
}

</script>

<template>
  <a-layout class="container-wrapper">
    <a-layout-sider width="35%" class="side-style">
      <a-list :bordered='true' item-layout='horizontal' size='small' :pagination='pagination' :data-source='data'>
        <template #renderItem='{ item }'>
          <a-list-item :key='item.orderid'>
            <a-list-item-meta>
              <template #title>
                <a @click="select_item_detail(item.key)">{{ item.orderid }}</a>
              </template>
              <template #avatar>
                <a-avatar :src='getLogo(item.company)' />
              </template>
              <template #description>
                <div>
                  <span>{{ getCompanyName(item.company) }}</span>
                </div>
                <div>
                  {{ item.update_time }}
                </div>
              </template>
            </a-list-item-meta>
            <div>{{ item.position }}</div>
          </a-list-item>
        </template>
      </a-list>
    </a-layout-sider>
    <a-layout-content width="65%" class="content-style">
      <div style="height: 100%;display: flex;align-items: center;justify-content: center;" v-if="select_order===0">
        <a-empty description="查看订单">
        </a-empty>
      </div>
      <div style="height: 100%" v-else>
        <a-flex vertical>
          <div>
            <a-page-header
              class="demo-page-header"
              style="border: 1px solid rgb(235, 237, 240)"
              title="订单信息"
              :sub-title="item_detail.id"
            >
              <template #tags>
                <a-tag color="blue">{{ is_order_running(item_detail.status) }}</a-tag>
              </template>
              <template #extra>
                <a-button key="1" type="primary" @click="applyAfterSale(item_detail.id)"
                          :disabled="isButtonAvailable(item_detail.status)">申请售后服务
                </a-button>
              </template>
              <a-descriptions size="small" :column="3">
                <a-descriptions-item label="订单号">
                  #{{ item_detail.id }}
                </a-descriptions-item>
                <a-descriptions-item label="订单创建时间">
                  {{ dayjs.unix(item_detail.create_time).format('YYYY-MM-DD HH:mm:ss') }}
                </a-descriptions-item>
                <a-descriptions-item label="（预计）到达时间">
                  {{ dayjs.unix(item_detail.arriveTime).format('YYYY-MM-DD HH:mm:ss') }}
                </a-descriptions-item>
                <a-descriptions-item label="上一状态更新时间">
                  {{ dayjs.unix(item_detail.last_update_time).format('YYYY-MM-DD HH:mm:ss') }}
                </a-descriptions-item>
                <a-descriptions-item label="运费">
                  {{ item_detail.postage }} 元
                </a-descriptions-item>
                <a-descriptions-item label="运费支付方式">
                  {{ item_detail.payType === 0 ? '包邮' : '到付' }}
                </a-descriptions-item>
              </a-descriptions>
            </a-page-header>
          </div>
          <div style="overflow: hidden; margin-top: 20px;padding-bottom: 0;">
            <a-steps :items="items"
                     size="small"
                     :current="1"
                     label-placement="vertical"
            >
            </a-steps>
          </div>
          <a-divider />
          <div style="width: 100%;padding: 10px;">
            <a-flex>
              <div style="width: 50%">
                <a-card title="基础信息" style="width: 80%;margin: 5%;height: 90%;">
                  <a-row>
                    <a-col :span="12">
                      <a-statistic title="重量(Kg)" :value="item_detail.base_info.weight" :precision="2"
                                   style="margin-right: 50px" />
                    </a-col>
                    <a-col :span="12">
                      <a-statistic title="货物种类" :value="getGoodsType(item_detail.base_info.type)" />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="12">
                      <a-statistic title="付款方式" :value="item_detail.base_info.payType" style="margin-right: 50px" />
                    </a-col>
                    <a-col :span="12">
                      <a-statistic title="取件方式"
                                   :value="showPickUpMethods(item_detail.base_info.pickup_methods,item_detail.status)" />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="12">
                      <a-statistic title="快递员" :value="item_detail.base_info.courier_name"
                                   style="margin-right: 50px">
                      </a-statistic>
                    </a-col>
                    <a-col :span="12">
                      <a-statistic title="快递员电话" :value="item_detail.base_info.courier_phone" group-separator="">
                      </a-statistic>
                    </a-col>
                  </a-row>
                </a-card>
              </div>
              <div style="width: 50%;">
                <a-card style="width: 80%;margin: 5%;">
                  <a-card-meta title="发货地址">
                    <template #avatar>
                      <a-avatar size="large"
                                :style="{ backgroundColor: '#fce8cf',color:'black', verticalAlign: 'middle' }" gap="4">
                        发
                      </a-avatar>
                    </template>
                  </a-card-meta>
                  <div style="display: flex;align-items: center;justify-content: center;">
                    <a-descriptions
                      :title="item_detail.shipping.province+item_detail.shipping.city+item_detail.shipping.district+item_detail.shipping.detail"
                      :colon="true"
                      layout="vertical"
                    >
                      <a-descriptions-item label="发货人">{{ item_detail.shipping.name }}</a-descriptions-item>
                      <a-descriptions-item label="手机">{{ item_detail.shipping.phone }}</a-descriptions-item>
                    </a-descriptions>
                  </div>
                </a-card>
                <a-card style="width: 80%;margin: 5%">
                  <a-card-meta title="收货地址">
                    <template #avatar>
                      <a-avatar size="large"
                                :style="{ backgroundColor: '#a1affc',color:'black', verticalAlign: 'middle' }" gap="4">
                        收
                      </a-avatar>
                    </template>
                  </a-card-meta>
                  <a-descriptions
                    :title="item_detail.delivery.province+item_detail.delivery.city+item_detail.delivery.district+item_detail.delivery.detail"
                    layout="vertical"
                  >
                    <a-descriptions-item label="收货人姓名">{{ item_detail.delivery.name }}</a-descriptions-item>
                    <a-descriptions-item label="手机">{{ item_detail.delivery.phone }}</a-descriptions-item>
                  </a-descriptions>
                </a-card>
              </div>
            </a-flex>
          </div>
        </a-flex>
      </div>

    </a-layout-content>
  </a-layout>

</template>

<style scoped>
.demo-page-header :deep(tr:last-child td) {
  padding-bottom: 0;
}

.container-wrapper {
  height: 97%;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.side-style {
  text-align: center;
  height: 100%;
  line-height: 120px;
  color: black;
  background-color: white;
  width: 20%;
  overflow: auto;
}

.content-style {
  text-align: center;
  line-height: 120px;
  color: #FFF;
  background-color: white;
  width: 80%;
  overflow: auto;
}
</style>