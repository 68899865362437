<script setup>
import { reactive, ref } from 'vue'
const tabList = reactive([
  {
    key: 'tab1',
    tab: '个人信息',
  },
  {
    key: 'tab2',
    tab: '修改密码',
  },
]);
const contentList = reactive({
  tab1: 'content1',
  tab2: 'content2',
});
const key = ref('tab1');
const noTitleKey = ref('app');
const onTabChange = (value, type) => {
  console.log(value, type);
  if (type === 'key') {
    key.value = value;
  } else if (type === 'noTitleKey') {
    noTitleKey.value = value;
  }
};
const title = ref("个人信息")
</script>

<template>
  <a-flex gap="middle" vertical>
    <a-card
      style="width: 100%;"
      v-model:title="title"
      :tab-list="tabList"
      :active-tab-key="key"
      @tabChange="key => onTabChange(key, 'key')"
    >
      <template #customTab="item">
      <span v-if="item.key === 'tab1'">
        {{ item.tab }}
      </span>
      </template>
      <template #extra>
        <a href="#">More</a>
      </template>
      {{ contentList[key] }}
    </a-card>
  </a-flex>
</template>

<style scoped lang="scss">

</style>