import { createRouter, createWebHistory } from 'vue-router'
import { message } from 'ant-design-vue'
import Login from '../views/login'
import OrderList from '../views/order-list'
import User from '../views/user'
import CustomerService from '@/views/customer-service'
import Community from '@/views/community'
import Index from '@/views/index'
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { requiresAuth: true },
    redirect: (to) => {
      return '/index'
    }
  }, {
    path: '/index',
    name: 'index_page',
    meta: { requiresAuth: true },
    component: Index
  },
  {
    path: '/orderList',
    name: 'orderList',
    label: 'orderList',
    component: OrderList,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: true }
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    meta: { requiresAuth: true }
  }, {
    path: '/customer-service',
    name: '客服服务',
    component: CustomerService,
    meta: { requiresAuth: true }
  }, {
    path: '/community',
    name: '学习社区',
    component: Community,
    meta: { requiresAuth: true }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  if (
    to.meta?.requiresAuth &&
    to.name !== 'login' &&
    (!Cookies.get('username') || !Cookies.get('token'))
  ) {
    message.error('用户未登录或已过期！')
    return {
      name: 'login'
    }
  }
})

export { routes }

export default router
