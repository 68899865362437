<template>
  <div
    :style="{
      position: 'relative',
      minWidth: !state.collapse ? '200px' : '80px',
      height: 'calc(100vh - 64px)'
    }"
  >
    <Sider
      :collapsed='state.collapse'
      collapsible
      :trigger='null'
      @collapse='() => (state.collapse = !state.collapse)'
    >
      <Menu v-model:selectedKeys='selectedKeys'
            :openKeys="['sub1']" mode='inline'>
        <SubMenu key='sub1'>
          <template #icon>
            <IconFont type='icon-icon_yingyongguanli' />
          </template>
          <template #title>系统管理</template>
          <Item key='index'
          >
            <RouterLink to='/index'>
              <HomeOutlined />
              首页
            </RouterLink>
          </Item
          >

          <Item key='orderList'
          >
            <RouterLink to='/orderList'>
              <UnderlineOutlined />
              订单列表
            </RouterLink>
          </Item
          >
          <Item key='customerService'
          >
            <RouterLink to='/customer-service'>
              <ContactsOutlined />
              客服服务
            </RouterLink>
          </Item
          >
          <Item key='community'
          >
            <RouterLink to='/community'>
              <BookOutlined />
              学习社区
            </RouterLink>
          </Item
          >

        </SubMenu>

      </Menu>
    </Sider>
    <div
      class='sider-footer'
      :style="{
        width: !state.collapse ? '200px' : '80px'
      }"
    >
      <Tooltip title='折起'>
        <div
          :class="state.collapse && 'collaspe-icon'"
          @click='() => (state.collapse = !state.collapse)'
          class='icon-wrapper'
        >
          <IconFont type='icon-zhedie' />
        </div
        >
      </Tooltip>
      <Divider v-if='!state.collapse' type='vertical' />
      <Tooltip title='退出登录'>
        <div @click='logout' v-if='!state.collapse' class='icon-wrapper'>
          <IconFont type='icon-tuichudenglu' />
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive } from 'vue'
import IconFont from '@/components/icon-font'
import { Layout, Menu, Divider, message, Tooltip } from 'ant-design-vue'
import { fetchLogOut } from '@/service'
import { RouterLink } from 'vue-router'
import Cookie from 'js-cookie'
import { HomeOutlined, UnderlineOutlined, FundOutlined, ContactsOutlined, BookOutlined } from '@ant-design/icons-vue'

const { SubMenu, Item } = Menu
const { Sider } = Layout
const props = defineProps({
  isLogin: Boolean
})
const state = reactive({
  collapse: false
})
const logout = () => {
  fetchLogOut().then((res) => {
    if (res.success) {
      message.success('退出成功')
      Cookie.remove('token')
      Cookie.remove('userId')
      Cookie.remove('username')
      location.href = 'login'
    }
  })
}
</script>

<style lang='scss' scoped>
.ant-layout-sider {
  position: fixed;
  left: 0;
  top: 64px;
  height: calc(100vh - 64px);
  background-color: #e6e9ee;
  transition: none;
}

.sider-footer {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 18px;
  align-items: center;

  .icon-wrapper {
    cursor: pointer;
  }

  .collaspe-icon {
    transform: rotate(180deg);
  }
}

::v-deep {
  .ant-menu {
    background-color: #e6e9ee;
    color: #686868;
  }

  .ant-menu-sub.ant-menu-inline {
    background: #e6e9ee;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #f2f3f7;
  }

  .ant-menu-submenu-selected {
    color: #686868;
  }

  .ant-menu-item-selected {
    color: #686868;
  }

  .ant-menu-item-active {
    color: #686868;
  }

  .ant-menu-submenu-active {
    color: #686868;
  }

  .ant-menu-light .ant-menu-item:hover {
    color: #686868;
  }

  .ant-menu-submenu:hover {
    color: #686868;
  }

  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }

  .ant-menu-light .ant-menu-submenu-title:hover {
    color: #686868;
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #686868;
  }

  .ant-menu-submenu-arrow {
    color: #686868;
  }

  .ant-menu-title-content {
    user-select: none;
  }

  .ant-menu-item-group-title {
    user-select: none;
  }
}
</style>
