<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { updatePickUpStation } from '@/service'
import { message } from 'ant-design-vue'

const props = defineProps({
  address: {
    type: String,
    required: false
  }, ifDoorToDoorDelivery: {
    type: Boolean,
    required: false
  }, city: {
    type: String,
    required: false
  }, orderId: {
    type: String,
    required: false
  }, default_address: {
    type: Object,
    required: false
  }
})

window._AMapSecurityConfig = {
  securityJsCode: 'f25da2540e40286fe781eeadc9959128'
}
let map = null
let walking_route = null
let aroundPoiList = ref([])
let availablePois = ref(0)
let user_lng = 0, user_lat = 0

onMounted(() => {
  console.log(props.default_address)
  AMapLoader.load({
    key: 'a7bf6b066667c92a6fd4696d7944c686',
    version: '2.0',
    plugins: [
      'AMap.ToolBar',
      'AMap.Scale',
      'AMap.PlaceSearch',
      'AMap.Geocoder',
      'AMap.Marker',
      'AMap.Walking'
    ]
  }).then(AMap => {

    let geocoder = new AMap.Geocoder({
      redius: 1000,
      extensions: 'all'
    })
    let lng = 0, lat = 0


    if (props.address != null && props.address.trim().length > 0) {
      geocoder.getLocation(props.address, (status, res) => {
        lng = res['geocodes'][0]['location']['lng']
        lat = res['geocodes'][0]['location']['lat']

        user_lng = lng
        user_lat = lat

        map = new AMap.Map('container', {
          zoom: 16,
          resizeEnable: true,
          center: [lng, lat],
          viewMode: '3D'
        })

        AMap.plugin(['AMap.Walking'], () => {
          walking_route = new AMap.Walking({
            map: map,
            autoFitView: true
          })
          map.add(walking_route)
        })


        // 添加比例尺
        let scale = new AMap.Scale()
        map.addControl(scale)

        // 添加点标记
        const marker = new AMap.Marker({
          position: new AMap.LngLat(lng, lat),
          title: '收货地址'
        })
        map.add(marker)

        // 搜索
        AMap.plugin(['Amap.PlaceSearch'], () => {
          let placeSearch = new AMap.PlaceSearch({
            pageSize: 5,
            pageIndex: 1,
            city: props.city,
            citylimit: true,
            map: map,
            // panel: 'my_panel',
            autoFitView: true
          })
          placeSearch.searchNearBy('菜鸟驿站', new AMap.LngLat(lng, lat), 5000)
          map.add(placeSearch)

          placeSearch.on('complete', res => {
            if (res['info'] === 'NO_DATA')
              message.warn('指定位置周边无驿站服务', 5)
            availablePois = res.poiList.count
            res.poiList.pois.forEach(item => {
              aroundPoiList.value.push({
                province: item.pname,
                city: item.cityname,
                district: item.adname,
                citycode: item.citycode,
                address: item.address,
                lng: item.location.lng,
                lat: item.location.lat,
                id: item.location.id,
                distance: item.distance,
                avatar_url: item.photos.length != null && item.photos.length > 0 ? item.photos[0]['url'] : '',
                name: item.name,
                type: item.type
              })
            })
          })

          placeSearch.on(['listElementClick', 'markerClick'], res => {
            let endLng = res['data']['location']['lng']
            let endLat = res['data']['location']['lat']
            walking_route.clear()
            walking_route.search(new AMap.LngLat(lng, lat), new AMap.LngLat(endLng, endLat), (status, res) => {
              console.log(status)
              console.log(res)
            })
          })
        })


      })
    } else {
      message.warn('请根据指定快件修改地址~')
      let defaultAddress = props.default_address['region'] + props.default_address['detailed']
      geocoder.getLocation(defaultAddress, (status, res) => {
        lng = res['geocodes'][0]['location']['lng']
        lat = res['geocodes'][0]['location']['lat']

        user_lng = lng
        user_lat = lat

        map = new AMap.Map('container', {
          zoom: 16,
          resizeEnable: true,
          center: [lng, lat],
          viewMode: '3D'
        })

        const marker = new AMap.Marker({
          position: new AMap.LngLat(lng, lat),
          title: '收货地址'
        })
        map.add(marker)
        let scale = new AMap.Scale()
        map.addControl(scale)
      })
    }
  })
})


const itemListClick = (item_lng, item_lat) => {
  walking_route.clear()
  walking_route.search([user_lng, user_lat], [item_lng, item_lat], (status, res) => {
    console.log(status)
    console.log(res)
  })
}

const emit = defineEmits(['confirm'])

const confirmPickupStation = (item) => {
  // 设置最终取件驿站
  let form = new FormData()
  form.append('order_id', props.orderId)
  form.append('pickup_methods', props.ifDoorToDoorDelivery === true ? 2 : 1)
  form.append('province', item.province)
  form.append('city', item.city)
  form.append('district', item.district)
  form.append('address', item.address)
  form.append('lng', item.lng)
  form.append('lat', item.lat)
  form.append('name', item.name)
  form.append('type', item.type)

  updatePickUpStation(form).then(res => {
    message.info('更改成功！')
  }).catch(err => {
    message.warn('更改失败' + err.errorMsg)
  })
  emit('confirm', {
    'orderId': props.orderId,
    'pickUpMethod': props.ifDoorToDoorDelivery ? 2 : 1
  })


}
const cancelConfirmPickUpStation = () => {
}

</script>

<template>
  <div id='container'>
    <div id='my_panel' v-if="aroundPoiList.length>0">

      <a-list item-layout="vertical" size="small" :data-source="aroundPoiList">
        <template #renderItem="{ item }">
          <a-list-item>
            <!--            <template #extra>-->
            <!--              <a @click="chose(item)">选择这里</a>-->
            <!--            </template>-->
            <a-list-item-meta
              :description="'地址：'+item.address+',距离：'+item.distance+'米'"
            >
              <template #title>
                <a @click="itemListClick(item.lng,item.lat)">{{ item.name }}</a>
              </template>
              <template #avatar>
                <a-avatar :src="item.avatar_url" />
              </template>
            </a-list-item-meta>

            <a-popconfirm
              :title="'确认选择该位置？距离'+item.distance+'米'"
              ok-text="确认"
              cancel-text="取消"
              @confirm="confirmPickupStation(item)"
              @cancel="cancelConfirmPickUpStation"
            >
              <a-button type="text">去这里</a-button>
            </a-popconfirm>

          </a-list-item>
        </template>
      </a-list>

    </div>
  </div>
</template>

<style scoped>

#container {
  width: 100%;
  height: 100%;
  position: relative; /* 添加相对定位 */
}

#my_panel {
  position: absolute;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  line-height: 10px;
  font-size: 5px;
  top: 10px;
  right: 10px;
  width: 280px; /* 当内容超过面板高度时启用滚动 */
  z-index: 10; /* 确保panel在最上层 */
}


</style>
