import http from './axios'
import unlockFilled from '@ant-design/icons-vue/lib/icons/UnlockFilled'

let base_url = process.env.VUE_APP_BASE_API

const fetchAfterSalesStatic = async (body) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/count/aftersale',
    data: body
  })
  if (data.code !== 0)
    return Promise.reject(data)
  return data

}

const fetchSalesStatic = async (body) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/count/sale',
    data: body
  })
  if (data.code !== 0)
    return Promise.reject(data)
  return data

}

const fetchMsgId = async () => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/common/id'
  })
  if (data.success) {
    return data
  }
  return Promise.reject(data)
}

const fetchLogin = async (body) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/user/login',
    data: body
  })
  if (data.success) {
    http.defaults.headers.common['Authorization'] = data.data
    return data
  } else
    return Promise.reject(data)
}


const fetchRegister = async (body) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/user/register',
    data: body
  })
  if (data.success)
    return data
  return Promise.reject(data)
}

const fetchBatchAfterSale = async (body) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/order/afterSaleBatch',
    data: body
  })
  if (data.code !== 0) {
    return Promise.reject(data)
  }
  return data
}

const fetchOperationLog = async (body) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/order/edit',
    data: body
  })
  return data
}

const fetchOrderList = async (current, size) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/order/list',
    params: {
      current: current,
      size: size
    }
  })
  return data
}

const fetchOrderDetail = async (id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/order/detail',
    params: {
      'order_id': id
    }
  })
  return data
}

const fetchOrderDetailByOrderId = async (id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/order/detail/order_id',
    params: {
      'order_id': id
    }
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}


const fetchOrderDetailByRoomId = async (roomId) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/order/detail/roomId',
    params: {
      'roomId': roomId
    }
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchAddressInfo = async (id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/address/detail',
    params: {
      'id': id
    }
  })
  if (data.success)
    return data
  return Promise.reject(data)
}


const fetchAddressList = async (current, size) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/address/list',
    params: {
      'current': current,
      'size': size
    }
  })
  if (data.success)
    return data
  return Promise.reject(data)
}

const updatePickUpMethod = async (order_id, method) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/order/editPickUpMethod',
    params: {
      'order_id': order_id,
      'pickup_method': method
    }
  })
  if (data.success)
    return data
  return Promise.reject(data)
}

const commitNewAddress = async (formdata) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/address/add',
    data: formdata
  })
  if (data.success)
    return data
  return Promise.reject(data)
}

const updateAddress = async (form_data) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/address/modify',
    data: form_data
  })
  if (data.success)
    return data
  return Promise.reject(data)
}


const fetchAddressDetail = async (id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/address/info',
    params: {
      'id': id
    }
  })
  if (data.success)
    return data
  return Promise.reject(data)
}

const deleteAddress = async (id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/address/delete',
    params: {
      'id': id
    }
  })
  if (data.success)
    return data
  return Promise.reject(data)
}

const fetchGoodsInfo = async (goods_id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/goods/info',
    params: {
      'id': goods_id
    }
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}


const fetchCourierInfo = async (id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/courier/info',
    params: {
      'id': id
    }
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchLogOut = async () => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/user/logout'
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchBlogsList = async (current, size) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/blogs/list',
    params: {
      'current': current,
      'size': size
    }
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchUserInfo = async (id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/user/userinfo',
    params: {
      'id': id
    }
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchNewMsgId = async () => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/chat-msgs/id/msg'
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchNewWsGeneralId = async () => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/chat-msgs/id/general'
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const commitNewMessage = async (form) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/chat-msgs/new',
    data: form
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchChatRooms = async (current, size) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/chat-rooms/list',
    params: {
      'current': current,
      'size': size
    }
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchChatMessages = async (room_id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/chat-msgs/list',
    params: {
      'room_id': room_id
    }
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchChatRoomsUsers = async (room_id) => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/'
  })
}

const commitNewChatRoom = async (form) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/chat-rooms/new',
    data: form
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchWorkingOrders = async () => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/order/list/working'
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchAvailableCustomerServices = async () => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/user/customer-services'
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}


const updatePickUpStation = async (form) => {
  const { data } = await http({
    method: 'POST',
    url: base_url + '/order/confirmPickUpStation',
    data: form
  })


  if (!data.success)
    return Promise.reject(data)
  return data
}

const fetchDefaultAddress = async () => {
  const { data } = await http({
    method: 'GET',
    url: base_url + '/address/default'
  })
  if (!data.success)
    return Promise.reject(data)
  return data
}

export {
  fetchLogin,
  fetchWorkingOrders,
  fetchUserInfo,
  fetchNewMsgId,
  commitNewChatRoom,
  fetchChatRooms,
  fetchChatMessages,
  fetchAvailableCustomerServices,
  fetchNewWsGeneralId,
  updatePickUpStation,
  fetchRegister,
  fetchOrderList,
  fetchCourierInfo,
  fetchOrderDetailByOrderId,
  updatePickUpMethod,
  fetchAddressDetail,
  fetchLogOut,
  fetchBlogsList,
  fetchOrderDetail,
  fetchSalesStatic,
  fetchOperationLog,
  fetchBatchAfterSale,
  fetchAfterSalesStatic,
  fetchAddressInfo,
  fetchAddressList,
  commitNewAddress,
  commitNewMessage,
  updateAddress,
  deleteAddress,
  fetchGoodsInfo,
  fetchOrderDetailByRoomId
}

export class fetchAfterSale {
}