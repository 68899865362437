<script setup>
import {
  Form,
  FormItem,
  Input,
  InputPassword,
  Button,
  message,
  Modal
} from 'ant-design-vue'
import { reactive, ref } from 'vue'
import { fetchLogin, fetchRegister } from '@/service'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import Cookies from 'js-cookie'

const useForm = Form.useForm

/**
 * 登录用表单字段对象
 * @type {Reactive<{password: string, code: string, username: string}>}
 */
const formState = reactive({
  password: '',
  phone: ''
})
const checkPassword = async (_rule, value) => {
  if (value === '')
    return Promise.reject('请再次确认密码')
  else if (value !== registerForm.password)
    return Promise.reject('两次输入密码不一致')
  else
    return Promise.resolve()
}
/**
 * 登陆表单验证规则
 * @type {Reactive<{password: [{message: string, required: boolean}], username: [{message: string, required: boolean}]}>}
 */
const rulesRef = reactive({
  username: [
    {
      required: true,
      message: '请输入用户名或者邮件或者手机号'
    }
  ],
  password: [
    {
      required: true,
      message: '请输入密码'
    }
  ]
})

const { validate, validateInfos } = useForm(formState, rulesRef)

/**
 * 注册用表单对象
 * @type {Reactive<{password: string, checkPassword: string, username: string}>}
 */
const registerForm = reactive({
  username: '',
  password: '',
  email: '',
  phone: '',
  checkPassword: ''
})
/**
 * 注册用表单规则
 * @type {Reactive<{password: [{message: string, required: boolean},{pattern: RegExp, message: string}], checkPassword: [{validator: ((function(*, *): Promise<void>)|*), message: string, required: boolean},{pattern: RegExp, message: string}], username: [{message: string, required: boolean}]}>}
 */
const registerRules = reactive({
  username: [
    {
      required: true,
      message: '请输入用户名'
    }
  ],
  password: [
    {
      required: true,
      message: '请输入密码'
    }, {
      pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // 至少8个字符，至少包含一个字母和一个数字
      message: '密码至少8个字符，且包含至少一个字母和一个数字'
    }
  ],
  checkPassword: [
    {
      required: true,
      message: '确认密码是否一致',
      validator: checkPassword
    }, {
      pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // 至少8个字符，至少包含一个字母和一个数字
      message: '密码至少8个字符，且包含至少一个字母和一个数字'
    }
  ], phone: [
    {
      required: true,
      message: '请输入手机号'
    }, {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: '手机号不符合规则'
    }
  ],
  email: [
    {
      required: true,
      message: '请输入邮箱地址'
    }, {
      pattern: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
      message: '邮箱地址不符合规则'
    }
  ]
})


const { validate: registerValidate, validateInfos: registerValidateInfos } =
  useForm(registerForm, registerRules)

let currentAction = ref('login')

const router = useRouter()

/**
 * 确认注册回调函数
 */
const handleLogin = () => {
  validate().then(() => {
    let form_data = new FormData()
    form_data.append('phone', formState.phone)
    form_data.append('password', formState.password)
    fetchLogin(form_data).then((res) => {
      if (res.success) {
        message.success('登陆成功！')
        Cookies.set('token', res.data?.token)
        Cookies.set('username', res.data?.name)
        Cookies.set('userId', res.data?.userId)
        // Cookies.set
        location.href = 'index'
      } else {
        message.error('登陆失败！' + res.errorMsg)
      }
    }).catch((err) => {
      message.error(err.errorMsg)
    })
  })
}

const handleRegister = () => {
  registerValidate()
    .then(() => {
      let form_data = new FormData()
      form_data.append('name', registerForm.username)
      form_data.append('password', registerForm.password)
      form_data.append('phone', registerForm.phone)
      form_data.append('email', registerForm.email)
      fetchRegister(form_data).then((res) => {
        if (res.success === true) {
          message.success('注册成功')
          currentAction.value = 'login'
          formState.phone = res.data['phone']
        }
      })
    })
    .catch((err) => {
      console.log(err)
      message.error(err.msg)
    })
}
</script>
<template>
  <div class="login-wrapper">
    <div class="title-wrapper">
    </div>
    <div class="login-reg-panel">
      <div class="login-info-box">
        <h2>已有账号？</h2>
        <h3>欢迎登录账号！</h3>
        <button style="color:#2f2f2f" @click="() => (currentAction = 'login')">去登录</button>
      </div>
      <div class="register-info-box">
        <h2>没有账号？</h2>
        <h3>欢迎注册账号！</h3>
        <button style="color: #2f2f2f" @click="() => (currentAction = 'register')">去注册</button>
      </div>
      <div
        class="white-panel"
        :class="{ 'white-panel-left': currentAction === 'register' }"
      >
        <div class="login-show" v-if="currentAction === 'login'">
          <h1 class="title">登录</h1>
          <Form name="basic" autocomplete="off">
            <FormItem v-bind="registerValidateInfos.phone">
              <Input
                size="large"
                v-model:value="formState.phone"
                placeholder="手机号"
              >
                <template #prefix
                >
                  <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template
                >
              </Input>
            </FormItem>

            <FormItem v-bind="registerValidateInfos.password">
              <InputPassword
                size="large"
                v-model:value="formState.password"
                placeholder="密码"
              >
                <template #prefix
                >
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)"
                  />
                </template>
              </InputPassword>
            </FormItem>
            <FormItem>
              <div class="action-btn">
                <a href="">忘记密码？</a>
                <Button
                  type="primary"
                  :style="{ backgroundColor: '#202020', border: 'none' }"
                  @click="handleLogin"
                >登录
                </Button
                >
              </div>
            </FormItem>
          </Form>
        </div>
        <div class="register-show" v-else>
          <h1 class="title">注册</h1>
          <Form name="basic" autocomplete="off" :label-col="{ span: 6 }">
            <FormItem name="username" label="用户名" v-bind="registerValidateInfos.username">
              <Input
                v-model:value="registerForm.username"
                placeholder="请输入用户名"
              >
              </Input>
            </FormItem>
            <FormItem name="email" label="邮箱" v-bind="registerValidateInfos.email">
              <Input
                v-model:value="registerForm.email"
                placeholder="请输入邮箱地址"
              >
              </Input>
            </FormItem>
            <FormItem name="phone" label="手机号" v-bind="registerValidateInfos.phone">
              <Input
                v-model:value="registerForm.phone"
                placeholder="请输入手机号"
              >
              </Input>
            </FormItem>
            <FormItem name="password" label="密码" v-bind="registerValidateInfos.password">
              <InputPassword
                v-model:value="registerForm.password"
                placeholder="密码"
              >
              </InputPassword>
            </FormItem>
            <FormItem name="checkPassword" label="确认密码" v-bind="registerValidateInfos.checkPassword">
              <InputPassword
                v-model:value="registerForm.checkPassword"
                placeholder="再次输入密码"
              >
              </InputPassword>
            </FormItem>


            <FormItem>
              <div class="action-btn">
                <a></a>
                <Button
                  type="primary"
                  @click="handleRegister"
                  :style="{ backgroundColor: '#202020', border: 'none' }"
                >注册
                </Button
                >
              </div>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>


.login-wrapper {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/background.png');
  background-size: 100% 100%;


  overflow: hidden;
  background-clip: border-box;

  .login-reg-panel {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 40%;
    right: 0;
    left: 20%;
    margin: auto;
    min-width: 800px;
    height: 600px;
    background-image: url("../../assets/small.png");
    background-size: 100% 100%;

    .white-panel {
      background-color: rgba(255, 255, 255, 1);
      height: 600px;
      position: absolute;
      width: 50%;
      right: calc(50% - 50px);
      transition: 0.3s ease-in-out;
      z-index: 0;
      box-sizing: border-box;

      .login-show,
      .register-show {
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
        transition: 0.3s ease-in-out;
        color: #242424;
        text-align: left;
        padding: 30px;

        .title {
          font-size: 24px;
          font-weight: bolder;
          padding: 20px 0;
          color: #202020;
        }

        .action-btn {
          display: flex;
          width: 100%;

          a {
            display: block;
            line-height: 32px;
          }

          justify-content: space-between;
        }
      }
    }

    .white-panel-left {
      transition: 0.3s ease-in-out;
      right: calc(0px + 50px);
    }

    .login-info-box {
      display: flex;
      flex-direction: column;
      width: 30%;
      padding: 0 50px;
      top: 20%;
      left: 0;
      position: absolute;
      text-align: left;
      justify-content: center;
      font-family: 'Mukta', sans-serif;
      color: #2f2f2f;

      h2 {
        font-size: 24px;
        color: #2f2f2f;
        font-weight: bolder;
        font-weight: bolder;
        margin-bottom: 40px;
      }

      h3 {
        font-size: 20px;
        color: #2f2f2f;
        margin-bottom: 40px;
      }

      button {
        cursor: pointer;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        border: 1px solid #2f2f2f;
        border-radius: 2px;
        height: 25px;
      }
    }

    .register-info-box {
      width: 30%;
      padding: 0 50px;
      top: 20%;
      right: 0;
      position: absolute;
      text-align: left;
      font-family: 'Mukta', sans-serif;
      color: #2f2f2f;

      h2 {
        font-size: 24px;
        color: #2f2f2f;
        font-weight: bolder;
        font-weight: bolder;
        margin-bottom: 40px;
      }

      h3 {
        font-size: 20px;
        color: #2f2f2f;
        margin-bottom: 40px;
      }

      button {
        cursor: pointer;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        border: 1px solid #2f2f2f;
        border-radius: 2px;
        height: 25px;
      }
    }
  }
}


::v-deep {
  .ant-modal-header {
    background-color: #3b3b3b !important;
  }

  .ant-form-item-label {
    label {
      color: #202020;
    }
  }
}
</style>
