<script setup>
import { Layout, ConfigProvider } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import { ref, watch, onMounted } from 'vue'
import BreadHeader from '@/components/bread-header'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import Header from './components/header'
import Sider from './components/sider'
import jsCookie from 'js-cookie'
import axios from './service/axios'

const { Content } = Layout
const isLogin = ref(false)
const route = useRoute()

onMounted(() => {
  const token = jsCookie.get('token')
  if (token)
    axios.defaults.headers.common['Authorization'] = token
  else {
    if (!location.href.includes('login'))
      location.href = 'login'
  }

  window.addEventListener('hashchange', () => {
    console.log(location.pathname, 'change')
  })
})


watch(
  () => route.path,
  (newRoute) => {
    if (newRoute === '/login') {
      isLogin.value = true
    } else {
      isLogin.value = false
    }
  }
)
</script>
<template>
  <ConfigProvider :locale="zhCN">
    <Layout>
      <Header :isLogin="isLogin" />
      <Layout class="page-wrapper" :class="{ isLogin }">
        <Sider v-if="!isLogin" />
        <Content class="app-wrapper" :class="{ isLogin }">
          <ConfigProvider :locale="zhCN">
            <div class="bread-header-container">
              <BreadHeader v-if="!isLogin" />
            </div>
            <div class="scrollable-content">
              <!--              展示内容的容器-->
              <router-view />
            </div>
          </ConfigProvider>
        </Content>
      </Layout>
    </Layout>
  </ConfigProvider>
</template>

<style lang="scss" scoped>
html,
body {
  margin: 0 !important;
  overflow: hidden; /* 禁止全局滚动 */
  width: 100vw;
  height: 100vh;
}

.page-wrapper {
  box-sizing: border-box;
  padding-top: 50px;
  height: 100vh; /* 确保页面包装器的高度为视口高度 */
  width: 100%;
  overflow: hidden;
}

.app-wrapper {
  display: flex; /* 使用 flex 布局 */
  flex-direction: column; /* 垂直排列 */
  height: 100%; /* 充满父容器 */
  margin-top: 20px;
  margin-left: 20px;
  //overflow: hidden;
}

.bread-header-container {
  //margin-left: 20px;
  //margin-top: 20px;
}

.isLogin.page-wrapper {
  padding: 0;
}

.scrollable-content {
  flex-grow: 1; /* 让内容区扩展占满空间 */
  overflow-y: auto;
  box-sizing: border-box;
  //overflow: hidden;
  max-height: 100%;
}

.ant-layout-content {
  height: 100%; /* 确保内容区域高度 */
}

.isLogin.ant-layout-content {
  height: 100vh;
  margin: 0;
}

::v-deep .ant-layout-content {
  transition: all 0.2s;
}
</style>
