<template>
  <div style="height: 95%">
    <vue-advanced-chat
      height="100%"
      :current-user-id="currentUserId"
      :rooms="JSON.stringify(rooms)"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      :rooms-loaded="rooms_loaded"
      :loading-rooms="loading_rooms"
      :room-info-enabled="true"
      :user-tags-enabled="true"
      :show-footer="true"
      :show-files="false"
      :show-send-icon="true"
      :show-add-icon="true"
      :textarea-action-enabled="true"
      :textarea-auto-focus="true"
      @room-info="roomInfo($event.detail[0])"
      @add-room="addRoom"
      @send-message="sendMessage($event.detail[0],currentUserId)"
      @fetch-messages="fetchMessages($event.detail[0])"
    >
      <div slot="custom-action-icon">
        <a-popover>
          <template #content>
            <p>
              查询快递进度
            </p>
          </template>
          <a-button type="text" @click="whereIsShipment" :icon="h(QuestionCircleTwoTone)">
          </a-button>
        </a-popover>
      </div>
      <div slot="custom-action-icon">
        <a-popover>
          <template #content>
            <p>
              催促快递进度
            </p>
          </template>
          <a-button type="text" @click="urgentShipment" :icon="h(ExclamationCircleTwoTone)">
          </a-button>
        </a-popover>
      </div>
      <div slot="custom-action-icon">
        <a-popover>
          <template #content>
            <p>
              我要投诉
            </p>
          </template>
          <a-button type="text" @click="complainShipment" :icon="h(FrownTwoTone)">
          </a-button>
        </a-popover>
      </div>

    </vue-advanced-chat>
    <a-modal :open="addRoomOpen"
             :mask="true"
             :keyboard="true"
             title="选择进行中订单进行售后"
             :style="'color:black'"
             :closable="true"
             @cancel="cancelAddRoomModal"
             :mask-closable="true"
             :destroy-on-close="true"
             @ok="handleAddRoom"
    >
      <a-form
        :model="addRoomReq"
        layout="horizontal"
        ref="editAddressRef"
      >
        <a-form-item
          label='订单'
          has-feedback
          :name="['orderId']"
          :rules="[{ required: true, message: '请选择具体售后订单' }]"
        >
          <a-select v-model:value="addRoomReq.orderId" v-model:options="availableOrders" placeholder="选择售后单"
                    @change="orderIdLog"
          />
        </a-form-item>
        <a-form-item
          label="金额"
          :name="['afterSaleAmount']"
          :rules="[{required:true,message:'售后金额不能为空'},{validator:checkAmount,trigger:'change'}]"
        >
          <a-input
            prefix="￥" suffix="RMB" v-model:value="addRoomReq.afterSaleAmount"
            placeholder="申请售后金额" />
        </a-form-item>
        <a-form-item
          label="原因"
          :name="['description']"
          :rules="[{required:true,message:'售后原因不能为空'}]"
        >
          <a-textarea
            v-model:value="addRoomReq.description"
            placeholder="售后原因...."
            :auto-size="{ minRows: 5, maxRows: 10 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

</template>

<script setup>
import { register } from 'vue-advanced-chat'
import { ref, reactive, onMounted, onBeforeMount, h } from 'vue'
import Stomp from 'stompjs'
import SockJs from 'sockjs-client'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import eventBus from '@/utils/event_bus'
import {
  commitNewChatRoom,
  commitNewMessage, fetchAvailableCustomerServices, fetchChatMessages,
  fetchChatRooms,
  fetchNewMsgId,
  fetchNewWsGeneralId, fetchOrderDetailByRoomId, fetchUserInfo,
  fetchWorkingOrders
} from '@/service'
import { message } from 'ant-design-vue'
import isNumeric from 'ant-design-vue/es/_util/isNumeric'
import {
  FrownTwoTone,
  QuestionCircleTwoTone,
  ExclamationCircleTwoTone
} from '@ant-design/icons-vue'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
const statusMap = [
  '未发货', '待发车', '已发车', '到达中转', '已到达', '已签收'
]
const urgentShipment = () => {
  sendMessage({
    'content': '催促订单',
    'roomId': currentRoomId.value
  }, currentUserId.value)


  fetchOrderDetailByRoomId(currentRoomId.value).then(res => {
    let data = res.data
    sendMessage({
      'content': '您的订单' + data.order_id + '正在加急派送中，已为您催促相关人员派送，烦请耐心等待！',
      'roomId': currentRoomId.value
    }, currentRoomStaff.value)
  })
}
const complainShipment = () => {
  sendMessage({
    'content': '投诉订单',
    'roomId': currentRoomId.value
  }, currentUserId.value)

  fetchOrderDetailByRoomId(currentRoomId.value).then(res => {
    let data = res.data
    sendMessage({
      'content': '查询到相关订单' + data.order_id + '，您可以写下投诉意见，我们将会尽快为您处理，为您带来的不便还请谅解，稍后有专员为您处理！',
      'roomId': currentRoomId.value
    }, currentRoomStaff.value)
  })
}
const whereIsShipment = () => {
  sendMessage({
    'content': '查询订单进度',
    'roomId': currentRoomId.value
  }, currentUserId.value)


  fetchOrderDetailByRoomId(currentRoomId.value).then(res => {
    let data = res.data
    sendMessage({
      'content': '查询到您的订单' + data.order_id + '处于' + statusMap[data.status]
        + '状态，预计' + dayjs.unix(data.arriveTime).format('YYYY-MM-DD HH:mm:ss')
        + '送达，请耐心等待！',
      'roomId': currentRoomId.value
    }, currentRoomStaff.value)
  }).catch(err => {
    console.log(err)
  })
}

const shipping = () => {

}

const addRoom = () => {
  addRoomOpen.value = true
}
const checkAmount = async (_rule, value) => {
  if (!value) {
    return Promise.reject('请输入数值')
  }
  if (!isNumeric(value) || value < 0) {
    return Promise.reject('格式错误')
  }
  return Promise.resolve()
}
const orderIdLog = () => {
  console.log(addRoomReq.orderId)
}
const availableOrders = ref([])
const roomInfo = (event) => {
  console.log(event)
}


let addRoomOpen = ref(false)
let currentUserId = ref(Cookies.get('userId'))
let currentUserName = ref(Cookies.get('username'))
let rooms = ref([])
let loading_rooms = ref(true)
let rooms_loaded = ref(false)
let roomsPagination = reactive({
  current: 1,
  size: 10
})
let messages = ref([])
let messagesLoaded = ref(true)
const cancelAddRoomModal = () => {
  addRoomOpen.value = false
}
let customer_services = ref(new Set())

const handleAddRoom = () => {
  let form_data = new FormData()
  form_data.append('order_id', addRoomReq.orderId)
  form_data.append('reason', addRoomReq.description)
  addRoomReq.afterSaleAmount = parseFloat(addRoomReq.afterSaleAmount).toFixed(2)
  form_data.append('amount', addRoomReq.afterSaleAmount)
  let customer_services_arr = Array.from(customer_services.value)
  if (customer_services_arr.length > 0)
    form_data.append('staff', customer_services_arr[Math.floor(Math.random() * customer_services_arr.length)])
  else
    form_data.append('staff', 1)
  form_data.append('user', currentUserName.value)
  commitNewChatRoom(form_data).then(res => {
    cancelAddRoomModal()
    loadRoomAndMessages(roomsPagination.current, roomsPagination.size)
    message.success('添加售后单成功，客服稍后为您服务')
  }).catch(err => {
    message.warn(err.errorMsg)
    cancelAddRoomModal()
  })


}
const addRoomReq = reactive({
  orderId: '',
  description: '',
  afterSaleAmount: ''
})
const loadRoomAndMessages = (current, size) => {
  while (rooms.value.length > 0)
    rooms.value.pop()
  fetchChatRooms(current, size).then(res => {
    res.data.forEach(item => {
      let staff_value = {
        id: '',
        nickName: '',
        phone: '',
        email: ''
      }
      fetchUserInfo(item.staffId).then(res => {
        staff_value.id = res.data.id
        staff_value.nickName = res.data.nickName
        staff_value.phone = res.data.phone
        staff_value.email = res.data.email
        rooms.value.push({
          roomId: item.roomId,
          roomName: item.roomName,
          orderId: item.orderId,
          unreadCount: 0,
          lastMessage: messages.value[messages.value.length - 1],
          users: [
            {
              _id: currentUserId.value,
              username: currentUserName.value,
              status: {
                state: 'online',
                lastChanged: dayjs().unix()
              }
            }, {
              _id: staff_value.id,
              username: staff_value.nickName,
              phone: staff_value.phone,
              email: staff_value.email,
              status: {
                state: 'offline',
                lastChanged: dayjs().unix()
              }
            }
          ]
        })
      })

    })

  }).catch(err => {
    console.log(err)
  })
  loading_rooms.value = false
  rooms_loaded.value = true
}

onBeforeMount(() => {
  init_websocket()
  register()
  loadRoomAndMessages(roomsPagination.current, roomsPagination.size)
  fetchWorkingOrders().then(res => {
    res.data.forEach(item => {
      availableOrders.value.push({
        'label': item.order_id,
        'value': item.order_id
      })
    })
  })
  fetchAvailableCustomerServices().then(res => {
    customer_services.value.add(...res.data)
  })

})
let stomp_client = null
let websocket_url = process.env.VUE_APP_WEBSOCKET_ADDRESS

const init_websocket = () => {
  const socket = new SockJs(websocket_url)
  stomp_client = Stomp.over(socket)
  stomp_client.debug = null
  // stomp.connect()函数一共有三个参数：
  // 1. 请求头信息
  // 2. 连接成功回调
  // 3. 失败回调
  stomp_client.connect(
    {
      'userId': currentUserId.value,
      'Authorization': Cookies.get('token')
    }, websocketOnConnected, websocketOnError
  )

  // stomp_client.disconnect(()=>{
  //   // 断开连接回调函数
  // })
  stomp_client.heartbeat.outgoing = 20000
  // todo:将incoming设为0表示客户端不接受来自服务端的心跳检测包，但是也应该对服务器状态进行监听吧？
  stomp_client.heartbeat.incoming = 0
}
onMounted(() => {
  eventBus.on('applyAfterSale', event => {
    console.log(event)
    addRoom()
    addRoomReq.orderId = event.orderId
  })
})

const websocketOnConnected = () => {
  stomp_client.subscribe('/topic/public', websocketOnMessageReceived)
  fetchNewWsGeneralId().then(res => {
    let id = res.data
    stomp_client.send('/app/chat.addUser', {}, JSON.stringify({
      type: 'JOIN',
      id: id,
      timestamp: dayjs().unix(),
      userId: currentUserId.value,
      username: currentUserName.value
    }))

    stomp_client.subscribe('/user/')
  }).catch(err => {
    console.log(err)
    message.error(err)
  })

}

const websocketOnMessageReceived = (payload) => {
  let msg = JSON.parse(payload.body)
  // console.log('接收到消息', msg)
  if (msg.type === 'JOIN') {
    if (msg.userId === currentUserId.value)
      message.success('您已上线！')
  } else if (msg.type === 'LEAVE') {
    console.log(msg.sender + '下线！')
  } else {
    sendMessageSuccess(payload)
  }
}

const websocketSendMessage = (msg, room_id) => {
  stomp_client.send('/app/chat.sendMessage', {}, msg)
}

const websocketOnError = (error) => {
  console.log('websocket connection failed', error)
  message.warn('客服服务连接失败，稍后重试')
  setTimeout(() => {
    init_websocket()
  }, 10000)
}

const currentRoomId = ref(0)
const currentRoomStaff = ref('')

function fetchMessages({ room, options = {} }) {

  currentRoomId.value = room.roomId
  currentRoomStaff.value = room.users[1]._id

  while (messages.value.length > 0)
    messages.value.pop()
  fetchChatMessages(room.roomId).then(res => {
    res.data.forEach(item => {
      messages.value.push({
        _id: item.messageId,
        content: item.content,
        senderId: item.senderId,
        date: item.datetime.slice(0, 10),
        timestamp: item.datetime.slice(11, 19),
        system: item.ifSystem === 1,
        distributed: false,
        deleted: false,
        disableActions: true,
        disableReactions: true,
        saved: true
      })
    })
    messagesLoaded.value = true
  })
}

const sendMessageSuccess = (message) => {

  rooms.value.forEach(room => {
    if (currentRoomId.value === message.room_id) {
      // todo: push message to the message list

    }
  })

  messages.value = [
    ...messages.valueOf(),
    message
  ]
}


function sendMessage(payload, sender_id) {
  fetchNewMsgId().then(res => {
    let msg_id = res.data
    let new_msg = {
      _id: msg_id,
      content: payload.content,
      senderId: sender_id,
      room_id: payload.roomId,
      timestamp: new Date().toString().substring(16, 21),
      date: new Date().toDateString(),
      system: false,
      saved: false,
      seen: false,
      deleted: false,
      disableActions: true,
      disableReactions: true
    }


    websocketSendMessage(new_msg, payload.roomId)

    let new_msg_req = new FormData()
    new_msg_req.append('id', msg_id)
    new_msg_req.append('content', payload.content)
    new_msg_req.append('room_id', payload.roomId)
    new_msg_req.append('timestamp', dayjs().unix())
    new_msg_req.append('datetime', new Date().toDateString())
    new_msg_req.append('sender_id', sender_id)
    new_msg_req.append('if_system', 0)
    new_msg_req.append('if_seen', 0)
    new_msg_req.append('if_deleted', 0)
    commitNewMessage(new_msg_req).then(res => {
      new_msg.saved = true
      messages.value = [
        ...messages.value,
        new_msg
      ]
    }).catch(err => {
      message.warn(err)
    })

  }).catch(err => {
    console.log(err)

  })
}

</script>
<style scoped>
body {
  font-family: 'Quicksand', sans-serif;
}
</style>