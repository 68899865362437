<template>
  <div class="card-container-style">
    <a-flex style="margin-bottom: 0;padding-bottom: 0">
      <div style="display:flex;align-items: center;justify-content: center;width: 100%">
        <a-input-search
          :value="searchValue"
          placeholder="搜索文章"
          enter-button

          style="width: 70%"
          @search="onSearch"
        />
      </div>
    </a-flex>
    <a-divider></a-divider>
    <a-flex vertical>
      <a-list  item-layout="vertical" size="small" :pagination="pagination"
              :data-source="listData">
        <template #footer>
        </template>
        <template #renderItem="{ item }">
          <a-list-item :key="item.id">
            <template #actions>
              <span v-for="action in actions" style="cursor: pointer;" :key="action">
                <component :is="action.icon" style="margin-right: 8px;" @click="blogActivity(action._type,item.id)" />
                {{ action.text }}
              </span>
            </template>
            <template #extra>
            </template>
            <a-list-item-meta
              :description="item.author_name +' · 编辑于'+  dayjs.unix(item.editTime).format('YYYY-MM-DD HH:mm:ss') ">
              <template #title>
                {{ item.title }}
              </template>
              <template #avatar>
                <a-avatar size="large"
                          :style="{ backgroundColor: '#a1affc',color:'white', verticalAlign: 'middle' }" gap="4">
                  {{ item['author_name'].charAt(0) }}
                </a-avatar>
              </template>
            </a-list-item-meta>
            {{ item.content }}
          </a-list-item>
        </template>
      </a-list>
    </a-flex>
  </div>

  <a-float-button-group trigger="hover" type="default" :style="{ right: '24px' }">
    <template #icon>
      <EditOutlined />
    </template>

    <a-float-button />
    <a-float-button @click="myMessages" tooltip="我的消息">
      <template #icon>
        <BellOutlined />
      </template>
    </a-float-button>
    <a-float-button href="index" tooltip="返回首页">
      <template #icon>
        <HomeOutlined />
      </template>
    </a-float-button>
    <a-float-button @click="editBlog" tooltip="我要发表">
      <template #icon>
        <edit-outlined />
      </template>
    </a-float-button>
  </a-float-button-group>
</template>

<script setup>
import {
  BellOutlined,
  EditOutlined,
  HomeOutlined,
  LikeOutlined,
  MessageOutlined,
  StarOutlined
} from '@ant-design/icons-vue'
import { onMounted, reactive, ref } from 'vue'
import { fetchBlogsList, fetchUserInfo } from '@/service'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)


const myMessages = () => {
  console.log('my-messages')
}
const editBlog = () => {
  console.log('我要发表')
}
const listData = ref([])
let searchValue = ref()
const onSearch = () => {
  return null
}

const blogActivity = (action, blog_id) => {
  console.log(action)
  console.log(blog_id)
}

const fetchBlogsPage = (current, size) => {
  fetchBlogsList(current, size).then(res => {

    while (listData.value.length > 0)
      listData.value.pop()
    res.data.forEach(item => {
      fetchUserInfo(item.userId).then(user_info => {
        let user_name = user_info['data']['nickName']
        listData.value.push({
          'author_name': user_name,
          'id': item.id,
          'title': item.title,
          'content': item.content,
          'stars': item.stars,
          'commentsCount': item.commentsCount,
          'likes': item.likes,
          'createTime': item.createTime,
          'editTime': item.editTime
        })
        while (actions.value.length > 0)
          actions.value.pop()
        actions.value.push({
          icon: StarOutlined,
          text: item.stars
        })
        actions.value.push({
          icon: LikeOutlined,
          text: item.likes
        })
        actions.value.push({
          icon: MessageOutlined,
          text: item.commentsCount
        })
      }).catch(err => {
        console.log(err)
        let user_name = '匿名用户'
        listData.value.push({
          'author_name': user_name,
          'id': item.id,
          'title': item.title,
          'content': item.content,
          'stars': item.stars,
          'commentsCount': item.commentsCount,
          'likes': item.likes,
          'createTime': item.createTime,
          'editTime': item.editTime
        })
        while (actions.value.length > 0)
          actions.value.pop()
        actions.value.push({
          icon: StarOutlined,
          text: item.stars,
          _type: 0
        })
        actions.value.push({
          icon: LikeOutlined,
          text: item.likes,
          _type: 1
        })
        actions.value.push({
          icon: MessageOutlined,
          text: item.commentsCount,
          _type: 2
        })

      })

    })
    console.log(listData.value)
  })
}
onMounted(() => {
  fetchBlogsPage(1, pageSize.value)
})
const pageSize = ref(10)
const pagination = reactive({
  onChange: page => {
    fetchBlogsPage(page, pageSize.value)
  },
  pageSize: pageSize,
  total: 0
})
const actions = ref([])
</script>

<style scoped>
.card-container-style {
  margin: 10px;
  background: white;
  padding: 10px;
  border: solid 1px #cacacc;
  border-radius: 2px;
}
</style>